/// <reference types="jquery" />

function gotoLink(link:Location):boolean {
	// default action
	if (link.hostname != location.hostname) return true;
	if (link.pathname != location.pathname) return true;
	if (link.search != location.search) return true;

	// anchor link
	let content:JQuery = $('html,body');

	let targetHash:string = link.hash;
	let target:JQuery = $((targetHash == '#' || targetHash == '') ? 'html' : targetHash);
	let targetScrollPosition:number = target.offset().top - $('.navigation').height() - 20;

	let currentScrollPosition:number = $(window).scrollTop();

	let maxScrollPosition:number = $(document).height() - $(window).height();
	if (targetScrollPosition > maxScrollPosition) targetScrollPosition = maxScrollPosition;
	if (targetScrollPosition < 0) targetScrollPosition = 0;

	let scrollDistance:number = Math.abs(targetScrollPosition - currentScrollPosition);
	if (scrollDistance > 0) {
		// jump
		let scrollJumpThreshold:number = 1000;
		if (scrollDistance > scrollJumpThreshold) {
			let initScroll:number = targetScrollPosition + scrollJumpThreshold * (targetScrollPosition > currentScrollPosition ? -1 : 1);
			content.scrollTop(initScroll);
			content.css({ opacity: 0 });
		}
		// animation
		content.stop(true, false);
		content.animate({ scrollTop: targetScrollPosition, opacity: 1 }, 1000, 'easeOutQuart');
	}

	return false;
}

jQuery(document).ready(function():void {

	// remove wordpress attributes
	jQuery('.body img').each(function(index:number, element:HTMLElement):void {
		jQuery(this).attr({ 'width': 'auto', 'height': 'auto' });
	});

	// set click action
	$('a').click(function():boolean {
		return gotoLink(<any>($(this)[0]));
	});

	// remove hash
	if (location.hash != '') {
		gotoLink(location);
		history.replaceState('', '', location.pathname + location.search);
	}

	// sound
	const clickSound:HTMLAudioElement = <HTMLAudioElement>jQuery('#click-sound').get(0);
	jQuery('a').mouseover(function(event:JQuery.MouseOverEvent):void {
		clickSound.volume = 0.5;
		clickSound.currentTime = 0;
		clickSound.play();
	});
});

